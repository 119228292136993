import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { VDL_DATE_LOCALE_FACTORY } from '@vdlx/vdl-angular/core';
import * as _moment from 'moment';
const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  
  constructor(
    private _cookieService:CookieService,
    private http: HttpClient) { }
  private readonly LOGOUT_API_URL = environment.api_endpoint + 'logout';

  public logout(){
    const cookieExist = this.getCookie('NIC-JWT-EXPIRY');
    if (cookieExist === undefined) {
      this.userRedirection(false);
    } else {
      this.putCookie('LOGGED_OUT', 'true');
      this.closePopup();
      this.loggedUser(false);
    }
  }

  public reLogin() {
    const cookieExist = this.getCookie('RE_LOGIN');
    if (cookieExist !== undefined) {
      this.userRedirection(false);
    } else {
      this.putCookie('RE_LOGIN', 'true');
      this.closePopup();
      this.loggedUser(true);
    }
  }

  private userRedirection(reLogin) {
    if (reLogin === false) {
      let sigoutUrl = environment.logout_external_url;
      const isExternalUser = sessionStorage.getItem("isExternalUser");
      if (!isExternalUser) {
        sigoutUrl = environment.logout_internal_url;
      }
     setTimeout(() => { window.location.replace(sigoutUrl) }, 1000);
    } else {
      const isExternalUser = sessionStorage.getItem("isExternalUser");
      if (!isExternalUser) {
              window.location.replace(window.location.origin)
      } else {
          window.location.replace(environment.login_url)
      }
    }
  }

  public loggedUser(reLogin) {
    this.http.get(this.LOGOUT_API_URL, { withCredentials: true }).toPromise().then(resp => {
      sessionStorage.clear();
      this.userRedirection(reLogin);
    });
  }

  private closePopup() {
    this.putCookie('POPUP_STATUS', 'close');
    this.removeCookie('TIMER');
  }

  public removeCookie(key: string){
    const options = {
      domain:'.veritas.com',
      path:'/'
    }
    this._cookieService.remove(key, options);
  }

  public putCookieSecure(key: string, value: string){
    const de = moment().add(2,'hour').toDate();
    //console.log("putcookie date", de)
    const options = {
      domain:'.veritas.com',
      secure:true,
      expires: de,
      path:'/'
    }
    this._cookieService.put(key, value, options);
  }
  public putCookie(key: string, value: string){
    const options = {
      domain:'.veritas.com',
      path:'/'
    }
    this._cookieService.put(key, value, options);
  }

  public getAllCookie(){
    return this._cookieService.getAll();
  }
  public getCookie(key: string) {
    return this._cookieService.get(key);
  }
}