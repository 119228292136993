
/**
 * This function is used for language mapping for english,chinese and japanese languages.
 * By default language will be en-US.
 */
// import { logger } from '../../shared/system/logger';
export function languageMapping(profileLang: string) {
    console.log('languageMapping', profileLang);
    switch (profileLang) {
        case ('en_US'):
        case ('en-US'):
            profileLang = 'en-US';
            break;
        case ('ja'):
        case ('ja_JP'):
        case ('ja-JP'):
            profileLang = 'ja-JP';
            break;
        case ('zh'):
        case ('zh_CN'):
        case ('zh-CN'):
            profileLang = 'zh-CN';
            break;
        case ('fr'):
        case ('fr-CA'):
        case ('fr-FR'):
            profileLang = 'fr-FR';
            break;
        default:
            profileLang = 'en-US';
            break;
    }
    return profileLang;
}


/** Denotes the name and code of the default language */
export const DEFAULT_LANGUAGE = { name: 'English (United States)', code: 'en-US' };

/** Denotes the list of supported languages on the login page */
export const SUPPORTED_LANGUAGES: any[] = [
    { name: 'English (United States)', code: 'en-US' },
    { name: '简体中文', code: 'zh-CN' },
    { name: '日本語', code: 'ja-JP' }
];

/**
 * Method to get the code for the language given the language name
 * @param langName
 */
export function getLanguageCode(langName) {
    const language = SUPPORTED_LANGUAGES.find((l) => l.name === langName);
    if (language) {
        return language.code;
    } else {
        return DEFAULT_LANGUAGE.code;
    }
}

