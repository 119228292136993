<vdl-dialog-header class="popup-header">
  <vdl-dialog-title>
      Enter verification code
  </vdl-dialog-title>
</vdl-dialog-header>
<vdl-dialog-content>
  <div>
    To generate the Unlock Key, enter the verification code sent to your email address for additional verification.
  </div>
  <br>
  <div>
    Enter verification code<br>
    <input type="text" class="otp-input" required maxlength=6 [(ngModel)]="userInputOtp"  (ngModelChange)="validateInput($event)" autofocus autocomplete="off">
    <vdl-error *ngIf="!userInputValid">Invalid code. Try again.</vdl-error>
    <vdl-error *ngIf="verifyFail">Attempts left: {{ this.attemptLeft }}</vdl-error>
    <vdl-error *ngIf="goingToLogout">You have reached the maximum number of attempts and will be logged out.</vdl-error>
    <div [hidden]="resendCount >= resendLimit || goingToLogout">
      <br>
      Didn't receive any code?<br>
      <a (click)="disableReSend ? '' :resend()" [ngClass]="{ 'disabled-a': disableReSend }">Resend new code</a>
      <span>{{ this.resendInterval }}</span>
    </div>
    <div [hidden]="!otpException || goingToLogout" style="color:red"><br/>Some error occurred when requesting for OTP, Please <strong>Refresh</strong> this page. If this situation continues to occur, please contact Veritas Support.</div>
    <div [hidden]="resendCount < resendLimit || goingToLogout" style="color:darkgreen"><br/>If you do not receive any code, click <strong>Cancel</strong> and contact Veritas Support.</div>
  </div>
</vdl-dialog-content>
<vdl-dialog-actions>
  <vdl-button-bar>
    <button vdl-secondary-button (click)="cancel()">
      Cancel
    </button>
    <button vdl-primary-button (click)="verify()" [disabled]="!userInputValid || verifying || userInputOtp === ''">
      {{ this.buttonText }}
    </button>
  </vdl-button-bar>
</vdl-dialog-actions>
