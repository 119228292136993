import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { TopbarComponent } from './topbar/topbar.component';
import { VdlModule } from './vdl-module';
import { TokenInterceptor } from './token.interceptor.service'
import { DashboardComponent } from './dashboard/dashboard.component';
import { ROUTES } from './app.routes';
import { LogoutComponent } from './logout/logout.component';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { RouteGuardService } from './guard/route.guard';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ErrorModalComponent } from './service/error-modal/error-modal.component';
import { CookieLawModule } from 'angular2-cookie-law';
import { PayloadComponent } from './payload/payload.component';
import { TerminalComponent } from './terminal/terminal.component';
import { AutologoutPopupComponent } from './autologout-popup/autologout-popup.component';
import { CookieModule } from 'ngx-cookie';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { OtpPopupComponent } from './otp-popup/otp-popup.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent, TopbarComponent, DashboardComponent,
    PayloadComponent,TerminalComponent,
    LogoutComponent,
    ErrorModalComponent,
    AutologoutPopupComponent,
    OtpPopupComponent,
    ErrorpageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    VdlModule,
    BrowserAnimationsModule,
    CookieLawModule,
    RouterModule.forRoot(ROUTES, {  useHash: true }),
    RouterModule.forRoot(ROUTES, {  useHash: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    CookieModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    RouteGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en-US', 'fr-FR', 'zh-CN', 'ja-JP'] } },
  ],
  entryComponents:[ErrorModalComponent,AutologoutPopupComponent, OtpPopupComponent],
  bootstrap: [AppComponent ]
})
export class AppModule {}
