import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VdlIconRegistry } from '@vdlx/vdl-angular/icon';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { ErrorPageMessage } from '../errorpage/error-page-message';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'via-portal-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.scss']
})
export class ErrorpageComponent implements OnInit {
  triggerRTL: false;
  hasError = false;
  details = false;
  elevated = false;
  header = true;
  headerActions = true;
  headerIcon = true;

  errorContext = "";
  title = "";
  content = "";

  ngOnInit() {
    const  err = JSON.parse(sessionStorage.getItem("errorPageError"));
    if ( err.name === "ErrorPageMessage" ) {
      this.title = err.title
      this.content = err.description;
      if ( err.details !== undefined && err.details.length > 0) {
          this.hasError = true;
          this.errorContext = err.details;
      }
    }
  }

  constructor(
    vdlIconRegistry: VdlIconRegistry,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    vdlIconRegistry.addSvgIcon(
      'ux-veritas',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/netinsights.svg')
    );
  }

  public back() {
    window.location.href = environment.netinsight_console_url;
  }
}
