import { Component, OnInit,ViewChild, Inject,  OnDestroy} from '@angular/core';
import { FormControl, AbstractControl,Validators,FormGroup, RequiredValidator } from '@angular/forms';
import { DomSanitizer } from "@angular/platform-browser";
import { VdlIconRegistry } from '@vdlx/vdl-angular/icon';
import { TranslateService } from '@ngx-translate/core';
import { OatasapiService } from '../service/oatasapi.service';
import { DOCUMENT } from '@angular/common';
import SHA512 from 'crypto-js/sha512';
import { Router } from '@angular/router';
import { ErrorPageMessage } from '../errorpage/error-page-message';
import { VdlDialog, VdlDialogConfig, VdlDialogRef } from '@vdlx/vdl-angular/dialog';
import { OtpPopupComponent } from '../otp-popup/otp-popup.component';
import {
  NotificationStates,
  NotificationTypes,
  VdlNotification
} from '@vdlx/vdl-angular/notification';
import { AutoLogoutService } from "../service/auto-logout.service";
import { WSCloseEvent, WSMessageEvent } from '../service/type';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';

export interface AdvanceSearchRequest {

  vql: string;

  fields?: string[];

  offset?: number;

  pageCount?: number;

  sortBy?: string;

  sortOrder?: string;

}

@Component({
  selector: 'via-portal-app-payload',
  templateUrl: './payload.component.html',
  styleUrls: ['./payload.component.scss']
})
export class PayloadComponent implements OnInit,OnDestroy {
  @ViewChild('vdlNotification', { static: true })
  private notification: VdlNotification;
  private ITEM_USER_INPUTS = "user_inputs";
  public showTips = true;
  public showProgress = false;
  public authDone = false;
  public otpCheckDone = false;
  payloadForm = new FormGroup({});
  public dialogRef: VdlDialogRef<OtpPopupComponent>;
  public userInputs = {
    appliancesn: '',
    otp: '',
    passphrase: '',
    reason4unlock: ''
  };
  public config: VdlDialogConfig = {
    disableClose: true,
    hasBackdrop: true,
    width: '500px',
    height: '',
    autoFocus: true,
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
    }
  };
  public _confirm = '';
  public _text = '';
  public _appliancesn = '';
  private _skpws = null;
  private maxLength = 256;
  public remainingLength = this.maxLength;
  public isExternalUser = true;
  public isReasonRequired = true;
  private serialNumber = '';
  private clusterID = '';

  constructor(
        @Inject(DOCUMENT) private document: any,
        vdlIconRegistry: VdlIconRegistry,
        private oatasApi: OatasapiService,
        private translate: TranslateService,
        private _autoLogout: AutoLogoutService,
        public router: Router,
        private dialog: VdlDialog,
        private sanitizer: DomSanitizer)
  {
    vdlIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    vdlIconRegistry.addSvgIcon(
      'visibility',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility24px.svg'));
    vdlIconRegistry.addSvgIcon(
      'visibility_off',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/invisibility24px.svg'));
  }

  ngOnInit(): void {
    this.autofill();
    this.checkMaintenanceFlag();
    this.restore();
    this.payloadForm = new FormGroup({
        'applianceSN' : new FormControl(this.userInputs.appliancesn, [
           Validators.required,
           Validators.pattern('[A-Za-z0-9 -]+'),
           Validators.minLength(3), Validators.maxLength(128)]),
         'oneTimePassword': new FormControl(this.userInputs.otp, [
          Validators.pattern('^[0-9]{6,10}$')]),
        'password': new FormControl(this.userInputs.passphrase,
           [Validators.required,
            Validators.minLength(8),
            Validators.pattern('(\.)*[0-9]+(\.)*'),
            Validators.pattern('(\.)*[a-z]+(\.)*'),
            Validators.pattern('(\.)*[A-Z]+(\.)*'),
            Validators.pattern('(\.)*[^A-Za-z0-9]+(\.)*')]),
        'confirm': new FormControl(this._confirm,
          [Validators.required,
          this.matchingPasswords("password")]),
        'reason4unlock': new FormControl(this.userInputs.reason4unlock,
          [Validators.minLength(3),
            Validators.maxLength(256),
            Validators.pattern('^[a-zA-Z0-9\\s!\\"#$%&\'()*+,\\-./:;<=>?@\\[\\\\\\]^_`{|}~]+$'),
          ])
    });
  }

  ngOnDestroy() {
  }

  private forwardTimeout(){
    var epm = new ErrorPageMessage(
      "error-page.title.authTimeout",
      "error-page.content.authTimeout");
    this.router.navigateByUrl("/errorpage");
    sessionStorage.setItem("errorPageError", JSON.stringify(epm));
  }

  private redirectToNetworkError(event: any){
    var epm = new ErrorPageMessage(
      "error-page.title.networkerror",
      "error-page.content.networkerror", new Error(JSON.stringify(event)));
    this.router.navigateByUrl("/errorpage");
    sessionStorage.setItem("errorPageError", JSON.stringify(epm));
  }
  private checkMaintenanceFlag(){
    console.log("checkMaintenanceFlag begin");
    let ws = new WebSocket(environment.skpws_endpoint);
    let timeout_val = 60000; //1 minutes
    let ws_timeout: ReturnType<typeof setTimeout>;
    let ws_success = false;
    try {
    ws.addEventListener('open', () => {
      console.log("checkMaintenanceFlag listening connecting.")
      const getFlagsAction = {
        action: 'getFlags',
        version: '2023-02-03'
      };
      console.log("onConnectionOpen getFlagsAction btoa=" + JSON.stringify(getFlagsAction));
    ws.send(JSON.stringify(getFlagsAction));
      ws_timeout = setTimeout(()=>{
        console.log("maintenance connection timeout 1 minute, forward to timeout page.");
        ws.close();
        // this.forwardTimeout();
      }, timeout_val);
      console.log("checkMaintenanceFlag ending connecting.")
    })
    ws.addEventListener('message', (evt: MessageEvent)=>{
        console.log("checkMaintenanceFlag received message");
        console.log("getFlagsConnectionMessage begin", evt);
    ws_success = true;
    let wsData = JSON.parse(evt.data);
    console.log("json parse ", wsData);
    clearTimeout(ws_timeout);
    if (wsData.maintenance_value !== undefined) {
      switch( wsData.maintenance_value) {
        case "pre-inform":
          console.log("Current flag value is pre-inform.");
          this.notification.setNotification(
            this.translate.instant(wsData.maintenance_message),
              NotificationTypes.Persistent,NotificationStates.Error
              );
          this.onAuth();
          return;
        case "on-going":
          console.log("Current flag value is on-going.");
          var epm = new ErrorPageMessage(
            "error-page.title.onMaintenance",
            wsData.maintenance_message);
          this.router.navigateByUrl("/errorpage");
          sessionStorage.setItem("errorPageError", JSON.stringify(epm));
          // since it is in maintenance status, forward to maintenance page, no need auth.
          // this.onAuth();
          return;
        default:
          console.log("Currently UKP is not in maintenance status.");
          this.onAuth();
          return;
      }
    } else {
      this.onAuth();
      console.log("Currently UKP is not in maintenance status.");
    }
    ws_timeout = setTimeout(()=>{
        console.log("maintenance api connection timeout 1 mins, forward to timeout page.");
        ws.close();
        this.forwardTimeout();
      }, timeout_val);
    })
    ws.addEventListener('close', event=>{
      console.log("close check maintenance flag connection");
      clearTimeout(ws_timeout);
      //process scenario of ws connection is closed directly, no message or error callback.
      if (!ws_success){
        this.onAuth();
      }
      console.log("check maintenance flag websocket connection close.", event);
    })
    ws.addEventListener('error', (evt: MessageEvent)=>{
      console.log("connection error");
      clearTimeout(ws_timeout);
      console.error("check maintenance flag websocket Error.", evt);
      this.redirectToNetworkError(evt);
    })
   } catch(error) {
    clearTimeout(ws_timeout);
    this.onAuth();
    console.log('checkMaintenanceFlag error:', error);
    this.redirectToNetworkError("Network error");
   }
   console.log("checkMaintenanceFlag end");
   return;
  }
  private onAuth() {
    console.log("onAuth begin");
    let ws = new WebSocket(environment.skpws_endpoint);
    let timeout_val = 60000; //1 minutes
    let ws_timeout: ReturnType<typeof setTimeout>;
    let ws_success = false;
    ws.addEventListener('open', () => {
      console.log("listening connecting.")
      const authAction = {
        action: 'auth',
        ApplianceId: this.serialNumber,
        version: '2023-02-03'
      };
      console.log("onConnectionOpen authAction btoa=" + JSON.stringify(authAction));
      ws.send(JSON.stringify(authAction));
       ws_timeout = setTimeout(()=>{
           console.log("auth connection timeout 1 minute, forward to timeout page.");
           ws.close();
           this.forwardTimeout();
       }, timeout_val);
      console.log("onAuth ending connecting.")
    })
    ws.addEventListener('message', (evt: MessageEvent)=>{
      console.log("onAuth received message", evt);
      clearTimeout(ws_timeout);
      ws_success = true;
      let wsData = JSON.parse(evt.data);
      console.log("json parse ", wsData);
      if (wsData.type !== undefined) {
      switch( wsData.type) {
        case "authOK":
          this.authDone = true;
          this.isExternalUser = wsData.data.toUpperCase() === "TRUE";
          console.log("Is external user? " + this.isExternalUser)
          console.log("Auth websocket onAuthConnectionMessage successfully.");
          if (wsData.statusCode !== "3" && this.isExternalUser) { // 3 means OTP has been used
            this.dialogRef = this.dialog.open(OtpPopupComponent, this.config);
            this.dialogRef.afterClosed().subscribe(() => {
              this.otpCheckDone = true;
              console.log("dialog closed, " + this.otpCheckDone + ", " + this.authDone);
            })
          } else {
            this.otpCheckDone = true;
          }
          return;
        case "authFailure":
          console.log("Failed to run auth websocket onAuthConnectionMessage.");
          this.authDone = true;
          var epm;
          if (wsData.statusCode === "V-601-1-158") {
            epm = new ErrorPageMessage(
              "error-page.title.applicationError",
              "error-page.content.internalError");
            this.router.navigateByUrl("/errorpage");
            sessionStorage.setItem("errorPageError", JSON.stringify(epm));
            return;
          }
          epm = new ErrorPageMessage(
            "error-page.title.accessDenied",
            "error-page.content.accessDenied", new Error(JSON.stringify(wsData.data)));
          this.router.navigateByUrl("/errorpage");
          sessionStorage.setItem("errorPageError", JSON.stringify(epm));
          return;
        default:
          console.log("auth result is not correct.");
          this.authDone = true;
          epm = new ErrorPageMessage(
            "error-page.title.accessDenied",
            "error-page.content.accessDenied");
          this.router.navigateByUrl("/errorpage");
          sessionStorage.setItem("errorPageError", JSON.stringify(epm));
          return;
      }
    } else {
      console.log("Auth websocket onAuthConnectionMessage wsData.type is undefined.");
      this.authDone = true;
      epm = new ErrorPageMessage(
          "error-page.title.accessDenied",
          "error-page.content.accessDenied");
      this.router.navigateByUrl("/errorpage");
      sessionStorage.setItem("errorPageError", JSON.stringify(epm));
    }
    ws_timeout = setTimeout(()=>{
        console.log("connection timeout 1 mins, forward to timeout page.");
        ws.close();
        this.forwardTimeout();
      }, timeout_val);
    })
    ws.addEventListener('close', event=>{
      console.log("close connection");
      clearTimeout(ws_timeout);
      console.log("Auth websocket onAuthConnectionClose.", event);
      if (!ws_success) {
        this.redirectToNetworkError(event);
      }
      this.authDone = true;
    })
    ws.addEventListener('error', (evt: MessageEvent)=>{
      console.log("connection error");
      clearTimeout(ws_timeout);
      console.log("Auth websocket websocket onAuthConnectionError.", evt);
      this.authDone = true;
      this.redirectToNetworkError(evt);
    })
    console.log("onAuth end");
  }

  private restore() {
    const user_inputs=sessionStorage.getItem(this.ITEM_USER_INPUTS);
    if ( user_inputs === null) {
        return;
    }
    if (user_inputs.length > 0){
      this.userInputs = JSON.parse(user_inputs);
      this._confirm = this.userInputs.passphrase;
    }
  }

  private autofill() {
    let snValue = sessionStorage.getItem("applianceSN") === null ? "" : sessionStorage.getItem("applianceSN"); // url parameter form.
    if (snValue == "") { // cookie form.
      snValue = this.getCookie("applianceSN");
      this.deleteCookie("applianceSN")
    }
    if (snValue) {
      this.serialNumber = snValue;
      this.userInputs.appliancesn = this.serialNumber;
    }
  }

  public matchingPasswords(passwordKey: string) {
    return (confirmPassword: AbstractControl): { [key: string]: any } => {
        if (confirmPassword == null) {
          return null;
        }
        const password = this.payloadForm.get(passwordKey);
        if (password == null) {
          return null;
        }
        if (password.value !== confirmPassword.value) {
            confirmPassword.setErrors({ 'pattern' : '' });
            return {
                'pattern' : ''
            };
        }
        else {
            confirmPassword.setErrors( null );
            return null;
        }
    };
  }

  public isInvalid(control: AbstractControl) {
    if (control)
        return control.invalid && (control.dirty || control.touched);
  }

  public getValidatorError(controlName : string) {
    //console.log("starting " + controlName);
    const cn = this.payloadForm.get(controlName);
    if ( !cn || cn.valid )
      return ;
    let errorKey = '';
    Object.keys(cn.errors).forEach(element => {
        errorKey = 'pay-load.error.' + element ;
      });
    errorKey += "." + controlName;
    return errorKey;

  }

  public resetAction() {
    this.showTips = true;
    this.payloadForm.enable();
    this.payloadForm.reset({
      applianceSN: this.serialNumber
    });
    sessionStorage.removeItem(this.ITEM_USER_INPUTS);
    this._autoLogout.removeCookie("OATASPL");
  }
  private onConnectionOpen(ws :any): void {
    console.log("supportkey onConnectionOpen ", ws);
    var payload = {
      action: 'sign',
      version: this.userInputs.otp != "" ? "2020-04-15" : "2.0",
      ApplianceId: this.userInputs.appliancesn,
      Id_Type: "SrNo",
      otp: this.userInputs.otp,
      HSSP: SHA512(this.userInputs.passphrase+'\n').toString(),
      Reason: this.userInputs.reason4unlock,
    }
    console.log("onGenerate payload btoa=" + JSON.stringify(payload));
    ws.send(JSON.stringify(payload));
  }

  private onConnectionOpenSN(ws :any): void {
    console.log("supportkey onConnectionOpenSN ", ws, this);
    var payload = {
      action: 'advanceSearch',
      'vql': 'uid=' + this.userInputs.appliancesn,
    }
    console.log("advanceSearch payload btoa=" + JSON.stringify(payload));
    ws.send(JSON.stringify(payload));
  }


  private onConnectionMessage(evt: MessageEvent): void {
    console.log("supportkey websocket onConnectionMessage", evt);
    this.showProgress = false;
    let wsData = JSON.parse(evt.data);
    console.log("json parse ", wsData);
    this.handleResponseData(wsData);
  }

  private onConnectionClose(_evt?: WSCloseEvent): void {
    console.log("supportkey websocket onConnectionClose", _evt);
  }

  private onConnectionError(evt?: any): void {
    console.log("supportkey websocket onConnectionError ", evt);
    this.showProgress =false;
    this.notification.setNotification(
      this.translate.instant("pay-load.error.general.tryagain"),
        NotificationTypes.Persistent, NotificationStates.Error, null , 3000);
}

private handleResponseData(data :any): void {
  console.log("handleResponseData ", data);
  if (data.hasOwnProperty("statusCode")) {
    switch(data["statusCode"]) {
      case "V-601-1-158":
        var d = data["data"];
        console.log("V-601-1-158 ", d , " type ", typeof d);
        if(!this.isExternalUser){
          // if it's an internal user, change the reason box style according to the response
          // if not, stay default (true, required)
          if ( d !== undefined ) {
            this.isReasonRequired = (d == '1');
          }else{
            this.isReasonRequired = true;
          }
        }
        return;
      case "V-601-1-151":
        this._text = data["data"];
        this._appliancesn = this.userInputs.appliancesn;
        this.showTips = false;
        this.showProgress = false;
        this.payloadForm.disable();
        this._skpws.close();
        this._skpws = null;
        return;
      case "V-601-1-101":
      case "V-601-1-130":
      case "V-601-1-131":
      case "V-601-1-132":
      case "V-601-1-141":
        this.showProgress = false;
        this.notification.setNotification(
        this.translate.instant("pay-load.error.netinsight.authentication"),
          NotificationTypes.Persistent,NotificationStates.Error, null, 3000);
          //data["statusCode"],3000 );
        return;
      case "V-601-1-153":
      case "V-601-1-154":
        this.showProgress = false;
        this.notification.setNotification(
        this.translate.instant("pay-load.error.shi.checkPermission"),
          NotificationTypes.Persistent,NotificationStates.Error, null, 3000);
          //data["statusCode"],3000 );
        return;
      case "V-601-1-157":
        this.showProgress = false;
        this.notification.setNotification(
        this.translate.instant("pay-load.error.general.invalidRequest"),
          NotificationTypes.Persistent,NotificationStates.Error, "", 3000 );
      default:
        this.showProgress = false;
        this.notification.setNotification(
        this.translate.instant("pay-load.error.general.tryagain"),
          NotificationTypes.Persistent, NotificationStates.Error,null, 3000);
          //data["statusCode"],3000 );
        return;
        } //end switch
  }else{
    //invalid response
    /*this.showProgress = false;
    //this.notification.setNotification( data.toString(),
    this.notification.setNotification(
    this.translate.instant("pay-load.error.general.tryagain"),
      NotificationTypes.Persistent, NotificationStates.Error,
      this.translate.instant("pay-load.error.general.network"),3000);
    return;*/
  }
}

public onGenerate() {
    this.backupUserInput();
    console.log("formv1 generating support key", this);
    this.showProgress = true;
    if (this._skpws !=  null) {
      this._skpws.close();
      this._skpws = null;
    }
    // Clear existing notification
    if (this.notification != null){
      console.log("close notification", this.notification)
      this.notification.closeAll();
    }
    this._skpws = new WebSocket(environment.skpws_endpoint);
    this._skpws.onopen = this.onConnectionOpen.bind(this, this._skpws);
    this._skpws.onmessage = this.onConnectionMessage.bind(this);
    this._skpws.onclose = this.onConnectionClose.bind(this);
    this._skpws.onerror = this.onConnectionError.bind(this);
    /*
    this.oatasApi.BuildSecureKey(payload).then(
        data => {
          if (data.hasOwnProperty("statusCode")) {
            switch(data["statusCode"]) {
              case "V-601-1-151":
                this._autoLogout.putCookieSecure("OATASSK",data["data"]);
                this._text = data["data"];
                this.showTips = false;
                this.showProgress = false;
                this.payloadForm.disable();
                return;
              case "V-601-1-101":
              case "V-601-1-101":
              case "V-601-1-130":
              case "V-601-1-131":
              case "V-601-1-132":
              case "V-601-1-141":
                this.showProgress = false;
                this.notification.setNotification(
                this.translate.instant("pay-load.error.netinsight.authentication"),
                  NotificationTypes.Persistent,NotificationStates.Error,
                  data["statusCode"],3000 );
                return;
              default:
                this.showProgress = false;
                this.notification.setNotification(
                this.translate.instant("pay-load.error.general.tryagain"),
                  NotificationTypes.Persistent, NotificationStates.Error,
                  data["statusCode"],3000 );
                return;
                }//end switch
          }//end if
          //data net::ERR_CONNECTION_RESET
          this.showProgress = false;
          //this.notification.setNotification( data.toString(),
          this.notification.setNotification(
          this.translate.instant("pay-load.error.general.tryagain"),
            NotificationTypes.Persistent, NotificationStates.Error,
            this.translate.instant("pay-load.error.general.network"),3000);
          return;
        })
        .catch(e => {
          //HttpErrorResponse
          this.showProgress = false;
          //this.notification.setNotification( JSON.stringify(e) ,
          this.notification.setNotification(
          this.translate.instant("pay-load.error.general.tryagain"),
          NotificationTypes.Persistent, NotificationStates.Error,
          this.translate.instant("pay-load.error.general.excepion"),3000);
          });
        */
  }

  public disableSaveButton() {
    if (!this.payloadForm) {
      return true;
    }
    if (!this.showTips) {
      return true;
    }
    return this.payloadForm.invalid
  }

  private backupUserInput() {
    sessionStorage.setItem(this.ITEM_USER_INPUTS, JSON.stringify(this.userInputs));
  }

  public onTextValueChange(value: string){
    const length = value !== null ? value.length : 0;
    if(length > this.maxLength) {
      this.remainingLength = 0;
    } else {
      this.remainingLength = this.maxLength - length;
    }
  }

  public ApplianceSNLBlur() {
    console.log("ApplianceSNLBlur ", this.userInputs.appliancesn);
    if (this.userInputs.appliancesn.length != 0) {
      const advanceSearchRequest: AdvanceSearchRequest = {
        vql: `uid="${this._appliancesn}"`
      };

      if (this._skpws !=  null) {
        this._skpws.close();
        this._skpws = null;
      }
      // Clear existing notification
      if (this.notification != null){
        console.log("close notification", this.notification)
        this.notification.closeAll();
      }
      this._skpws = new WebSocket(environment.skpws_endpoint);
      this._skpws.onopen = this.onConnectionOpenSN.bind(this, this._skpws);
      this._skpws.onmessage = this.onConnectionMessage.bind(this);
      this._skpws.onclose = this.onConnectionClose.bind(this);
      this._skpws.onerror = this.onConnectionError.bind(this);
    }
  }

  public getCookie(name: string): string | undefined {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return undefined;
  }

  public deleteCookie(name: string): void {
    const cookies = document.cookie.split("; ");
    const updatedCookies = cookies
      .map((cookie) => {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return '';
        }
        return cookie;
      })
      .filter((cookie) => cookie !== '');
    document.cookie = updatedCookies.join("; ");
  }

  public onPassphraseChanged(): void {
    this.payloadForm.get("confirm").updateValueAndValidity();
  }
}
