import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { GlobalHelp } from '@vdlx/vdl-angular/global-help';
import { VdlIconRegistry } from '@vdlx/vdl-angular/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { ProfileService } from '../service/profile.service'
import { AppService } from '../service/app.service';
import { VdlDialog, VdlDialogConfig, VdlDialogRef } from '@vdlx/vdl-angular/dialog';
import { ErrorModalComponent } from '../service/error-modal/error-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { AutoLogoutService } from "../service/auto-logout.service";

@Component({
  selector: 'via-portal-top-bar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit {
  user = null;
  public userName = '';
  public userInitial = '';
  public apps;
  public appLen;
  public linkDisabled;
  public loader;
  public links: Array<GlobalHelp> = [
    {
      id: 0,
      name: 'Getting Started',
      route: ''
    },
    {
      id: 1,
      name: 'Admin Guide',
      route: ''
    }
  ];
  public dialogRef: VdlDialogRef<ErrorModalComponent>;
  lastCloseResult: string;
  public config: VdlDialogConfig = {
    disableClose: true,
    width: '500px',
    height: '',
    autoFocus: false,
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
    }
  };
  constructor(
    vdlIconRegistry: VdlIconRegistry,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _profileService: ProfileService,
    private _appService: AppService,
    private dialog : VdlDialog,
    private _autoLogout: AutoLogoutService,
    private translate: TranslateService
  ) {
    vdlIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    vdlIconRegistry.addSvgIcon(
      'netinsights',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/netinsights.svg')
    );

  }

  public ngOnInit() {
    this._profileService.get().then((user: any) => {
      if (user === undefined) {
        return;
      }
      this.user = user
      this.userName = this.user.firstName + ' ' + this.user.lastName;
      this.userInitial = this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
      sessionStorage.setItem("profileEmail", this.user.email);
      let isExternalUser= user.isExternal ? "true":"false";
      sessionStorage.setItem("isExternalUser", isExternalUser);
    });
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
          this.getApplicationInfo();
      }
    });
  }

  private getApplicationInfo() {
    this._appService.getAppsBrife().then(resp => {
      if (resp === undefined) {
        return;
      }
      const apps = resp['apps'];
      this.appLen = apps.filter((a) => a.app_id !== 'NetInsights');
      if ((this.apps && this.apps.length === 0) || (this.appLen && this.appLen.length === 0)) {
        this.linkDisabled = true;
      } else {
        this.linkDisabled = false;
        const currentLocation = window.location.origin;
        this.apps = apps.map((appInfo) => {
                if (appInfo.endpoint.indexOf(currentLocation) > -1) {
                    appInfo.isCurrent = true;
                }
                return appInfo;
                });
      }
    });
  }

  private openBasicDialog(mess) {
    this.dialogRef = this.dialog.open(ErrorModalComponent, this.config);
    this.config.data.message = mess;
    this.dialogRef.afterClosed().subscribe((result: string) => {
      this.lastCloseResult = result;
      this.dialogRef = null;
    });
  }


  public launch(endPoint: string, id: string) {
    if (endPoint && id) {
      this.loader = true;
      this._appService.getHealthCheck(id).then((res) => {
        if (res && res['status'] === 200) {
          window.location.replace(endPoint);
          this.loader = false;
        } else {
          this.openBasicDialog(this.translate.instant('dashboard.err-message'));
          this.loader = false;
        }
      }).catch((e) => {
        console.error(e);
        this.openBasicDialog(this.translate.instant('dashboard.err-message'));
        this.loader = false;
      })
    }
  }

  public goTo(route: string) {
    this._router.navigateByUrl(route);
  }

  globalHelpLinkClicked(event: GlobalHelp) {
    if (event.route) {
      this._router.navigate([event.route]);
    }
  }
  
  logout() {
    this.goTo("/logout");
  }
}
