import { Component, OnInit, Inject } from '@angular/core';
import {
  VDL_DIALOG_DATA,
  VdlDialogRef,
} from '@vdlx/vdl-angular/dialog';
import { AutoLogoutService } from '../service/auto-logout.service';

@Component({
  selector: 'via-portal-autologout-popup',
  templateUrl: './autologout-popup.component.html',
  styleUrls: ['./autologout-popup.component.scss']
})
export class AutologoutPopupComponent implements OnInit {
  public timeout = '';
  public gracePeriod;
  public timeInterval;
  public expirationPopup;
  public popupState=null;

  constructor(
    public dialogRef: VdlDialogRef<AutologoutPopupComponent>,
    @Inject(VDL_DIALOG_DATA) public data: any,
    private _autoLogoutSvc: AutoLogoutService
  ) {
    this.getAllCookie();
    if (this.timeout === '' && this.gracePeriod !== '') {
        const min = Math.floor(Number(this.gracePeriod/60));
        const sec = this.gracePeriod - (min * 60);
        if (sec === 0) {
          this.timeout = `${min}:00`;
        } else {
          this.timeout = `${min}:${sec}`;
        }
      }
   }

  ngOnInit() {
    if(this.data.type === 'expiration') {
      this.expirationPopup = true;
      this.timeout = '5:00';
      this.putCookie('TIMER', this.timeout);
    } else {
      this.expirationPopup = false;
    }
      this.timeInterval = setInterval(() => {
        this.timeCheck();
      }, 1000);
  }

  private timeCheck() {
    if (this.popupState === 'open') {
      if (this.timeout === '0:00') {
        clearInterval(this.timeInterval);
        this.logout();
      } else { 
        const timeArray: Array<any> = this.timeout.split(/[:]+/);
        let m = Number(timeArray[0]);
        const s = this.checkSecond((timeArray[1] - 1));
        if(Number(s) ===59){
          m = m-1;
        }
        this.timeout = m + ":" + s;
        this.putCookie('TIMER', String(this.timeout));
      }
    } else {
      clearInterval(this.timeInterval);
    }
  }

  private checkSecond(sec) {
    if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
    if (sec < 0) {sec = "59"};
    return sec;
  }

  public logout(){
    this.continue();
    this._autoLogoutSvc.logout();
  }

  public continue() {
      this.timeout = '';
      clearInterval(this.timeInterval);
      this.dialogRef.close();
  }

  public reLogin() {
    this.continue();
    this._autoLogoutSvc.reLogin();
  }

  public putCookie(key: string, value: string){
    this._autoLogoutSvc.putCookie(key, value);
    this.getAllCookie();
  }

  public getAllCookie(){
    const cookie = this._autoLogoutSvc.getAllCookie();
    for (const key in cookie) {
      if (cookie.hasOwnProperty(key)) {
        switch (key) {
          case 'NIC-GRACE-PERIOD' :
            this.gracePeriod = cookie[key];
            break;
          case 'TIMER' :
              this.timeout = String(cookie[key]);
              break;
          case 'POPUP_STATUS' :
              this.popupState = cookie[key];
              break;
          default :
             break;
        }
      }
    }     
  }
}