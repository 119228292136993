import { HttpErrorResponse } from "@angular/common/http";

export class ErrorPageMessage extends Error {
    readonly name = "ErrorPageMessage";
    readonly message: string;
    readonly description: string;
    readonly error: any | null;
    readonly title: string;
    readonly details : string;
    constructor(title : string, message : string, err : Error = null) {
        super(message); 
        this.title = title;
        this.description = message;
        if ( err ) {
            console.log("error parameter", err);
            if ( err instanceof HttpErrorResponse)
            {
                this.details = err.error;
            }else{
                this.details =  err.message;
            }            
        }
      }
}
