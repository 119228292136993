<div class="example-grid">
    <div class="example-col">
        <h4> </h4>
        <div [dir]="triggerRTL ? 'rtl' : 'ltr'">
  
        <vdl-card [elevated]="elevated"
                  class="vdl-sample-card">
          <vdl-card-header *ngIf="header">
            <vdl-icon vdl-card-header-image
                      class="vdl-card-header-image"
                      svgIcon="ux-veritas"
                      *ngIf="headerIcon"></vdl-icon>
            <vdl-card-title>{{ this.title | translate }} </vdl-card-title>
            <vdl-card-header-actions *ngIf="headerActions">
              <button vdl-icon-button>
                <vdl-icon fontSet="fontawesome"
                          fontIcon="fa-ellipsis-v"
                          size="24"></vdl-icon>
              </button>
            </vdl-card-header-actions>
          </vdl-card-header>
          <vdl-card-content >
            <div class="sample-card-content-text">{{ this.content | translate}}</div>
            <div class="sample-card-content-text" *ngIf="details && hasError" >{{ 'error-page.details' | translate }} {{ this.errorContext }}</div>
          </vdl-card-content>
          <vdl-card-actions >
            <div class="social-share">
              <button vdl-tertiary-button *ngIf="! details && hasError" (click)="details=!details">
                <vdl-icon fontSet="fontawesome" 
                    fontIcon="fa-angle-double-down">
                </vdl-icon> 
            </button>
              <button vdl-tertiary-button *ngIf="details && hasError" (click)="details=!details">
                <vdl-icon fontSet="fontawesome"
                    fontIcon="fa-angle-double-up"></vdl-icon>
                </button>
            </div>
            <!-- <div>
              <button vdl-tertiary-button (click)="back()">{{ 'error-page.action.back' | translate }}</button>
             </div> -->
          </vdl-card-actions>
        </vdl-card>
  
      </div>
    </div>
  
  </div>