import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly PROFILE_API_URL = environment.api_endpoint + 'profile';
  private currentProfile;
  constructor(private http: HttpClient) { }

  async get() {
    return await this.http.get(this.PROFILE_API_URL, { withCredentials: true }).toPromise();
  }
}
