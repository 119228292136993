import { Component, OnInit, Inject } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  VDL_DIALOG_DATA,
  VdlDialogRef,
} from '@vdlx/vdl-angular/dialog';
import { AutoLogoutService } from '../service/auto-logout.service';
import {environment} from "../../environments/environment";

@Component({
  selector: 'via-portal-otp-popup',
  templateUrl: './otp-popup.component.html',
  styleUrls: ['./otp-popup.component.scss']
})
export class OtpPopupComponent implements OnInit {
  public userInputOtp = "";
  public userInputValid = true;
  public disableReSend = true;
  public verifyFail = false;
  public verifying = false;
  public goingToLogout = false;
  public buttonText = "Proceed";
  public resendInterval = "";
  public resendCount = 0;
  public resendLimit = 1;
  public attemptLeft = 5;
  public otpException = false;
  constructor(
    public dialogRef: VdlDialogRef<OtpPopupComponent>,
    @Inject(VDL_DIALOG_DATA) public data: any,
    private _autoLogoutSvc: AutoLogoutService
  ) {

   }

  ngOnInit() {
    this.countdown()
  }

  private sendOtp() {
    let webSocket = new WebSocket(environment.skpws_endpoint);
    let ws_timeout: ReturnType<typeof setTimeout>;
    webSocket.addEventListener('open', () => {
      console.log("Listening connection.")
      const action = {
        action: 'requestOTP',
      };
      console.log("onRequestOTPOpen requestOTPAction btoa=" + JSON.stringify(action));
      webSocket.send(JSON.stringify(action));
    })
    webSocket.addEventListener('message', (evt: MessageEvent)=>{
      console.log("Received message", evt);
      let responseData = JSON.parse(evt.data);
      console.log("json parse ", responseData);
      if (responseData.data.status == "0" || responseData.data.status == "4"){ // An error occurred.
        this.otpException = true;
      }
    })
    webSocket.addEventListener('close', event=>{
      clearTimeout(ws_timeout);
      console.log("Websocket requestOTP Closed.", event);
    })
    webSocket.addEventListener('error', (evt: MessageEvent)=>{
      console.log("An error happen when accessing to API Gateway")
    })
    console.log("request OTP end");
  }

  public resend() {
    if (this.resendCount < this.resendLimit) {
      console.log("Resend the code");
      this.sendOtp();
      this.disableReSend = true;
      this.resendCount += 1;
    } else {
      console.log("Only 1 time resend allowed");
    }
  }

  public validateInput(value: string) {
    this.verifyFail = false;
    this.verifying = false;
    const reg = /^[0-9]{1,10}$/;
    console.log("validateInput: " + value);
    if (reg.test(value)) {
      console.log("validateInput pass");
      this.userInputValid = true;
    } else {
      console.log("validateInput fail");
      this.userInputValid = false;
    }
  }

  private countdown() {
    const numbers = interval(1000);
    const takeFourNumbers = numbers.pipe(take(60));
    takeFourNumbers.subscribe(
      x => {
        this.resendInterval = " after " + (60-x) + " s";
        this.disableReSend=true;
      },
      error => {},
      () => {
        this.resendInterval = "";
        this.disableReSend=false;
      });
  }

  public async verify() {
    this.verifyFail = false;
    this.verifying = true;
    console.log("verifying is "+ this.verifying);
    this.buttonText = "Verifying..";
    let webSocket = new WebSocket(environment.skpws_endpoint);
    let ws_timeout: ReturnType<typeof setTimeout>;
    webSocket.addEventListener('open', () => {
      console.log("Listening connection.")
      const action = {
        action: 'validateOTP',
        otp: this.userInputOtp
      };
      console.log("validateOTPAction btoa=" + JSON.stringify(action));
      webSocket.send(JSON.stringify(action));
    })
    webSocket.addEventListener('message', (evt: MessageEvent)=>{
      let responseData = JSON.parse(evt.data);
      console.log("json parse ", responseData);
      if (responseData.data !== undefined) {
        clearTimeout(ws_timeout);
        switch( responseData.data ) {
          case "1": // Valid
            console.log("verify pass");
            this.verifyFail = false;
            this.verifying = false;
            this.dialogRef.close();
            break;
          case "5": // Invalid
            console.log("verify fail");
            this.attemptLeft -= 1;
            if (this.attemptLeft <= 0) {
              console.log("Reach retry maximum");
              this.goingToLogout = true;
              this.sleep(1000);
              this._autoLogoutSvc.logout();
            } else {
              this.verifying = false;
              this.verifyFail = true;
              this.buttonText = "Proceed";
            }
            break;
          case "6":  // Expired

            break;
          case "7":  // Reach Maximum
            console.log("Reach retry maximum");
            this.goingToLogout = true;
            this.sleep(1000);
            this._autoLogoutSvc.logout();
            break;
        }
      } else {
        console.log("responseDara.type is undefined.");
      }
    })
    webSocket.addEventListener('close', event=>{
      clearTimeout(ws_timeout);
      console.log("Websocket validateOTP Closed.", event);
    })
    webSocket.addEventListener('error', (evt: MessageEvent)=>{
      console.log("An error happen when accessing to API Gateway")
    })
    console.log("validate OTP end");
  }

  public cancel() {
    this._autoLogoutSvc.logout();
  }

  private sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    })
  }
}
