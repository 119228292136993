import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {
  private readonly NOTIFICATION_API_ALL = environment.api_endpoint + 'notifications';
  private readonly NOTIFICATION_API = environment.api_endpoint + 'notification';

  constructor(private http: HttpClient) { }
  
  async getNotification(appliance){
     // const a = btoa(unescape(encodeURIComponent(appliance)))
      return await this.http.get(`${this.NOTIFICATION_API}?id=${appliance}`, {withCredentials: true}).toPromise() ;
  }

  async getAllNotification(){
    return await this.http.get(this.NOTIFICATION_API_ALL, {withCredentials: true}).toPromise();
  }

  async createNotification(payload){
    try {
        return await this.http
        .post(this.NOTIFICATION_API, JSON.stringify(payload), {withCredentials: true, observe: 'response'})
        .toPromise();
    } catch (error) {
      return this.handleError(error);
    }
  }

  async editNotification(payload){
    try {
      return await this.http
        .patch(this.NOTIFICATION_API, JSON.stringify(payload), {withCredentials: true, observe: 'response'})
        .toPromise();
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteNotification(id, createTime){
    try {
      const url = `${this.NOTIFICATION_API}/id/${id}/create_time/${createTime}`;
      return await this.http.delete(url, {withCredentials: true, observe: 'response'})
        .toPromise();
    } catch (error) {
      return this.handleError(error);
    }
  }

  private handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = {
        message :error.error.message
      }
    } else {
      // server-side error
      errorMessage = {
        code: error.status,
        message: error.message
      }
    }
    console.log(errorMessage);
    return errorMessage;
  }
}
