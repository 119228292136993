<div class="container">

    <vdl-card class="message">
        <vdl-card-content>
            <div class="content">
                <img class="banner-logo" role="presentation"
                    src="https://aadcdn.msftauthimages.net/dbd5a2dd-tndlfie83m2qnwslvfsqhs4qm-ajoybcthytjlh7hvc/logintenantbranding/0/bannerlogo?ts=637165310065464007">
                <p *ngIf="!signedOut" class="header">{{'logout.header' | translate}}</p>
                <div *ngIf="!signedOut" class="progress">
                    <vdl-progress-spinner mode="indeterminate" diameter="60" spinnerColor='#0070bd'
                        trackColor='#d8eeff'>
                    </vdl-progress-spinner>
                </div>
                <p *ngIf="signedOut" class="header">{{'logout.signout.header' | translate}}</p>
                <p *ngIf="signedOut">{{'logout.signout.text' | translate}}</p>
            </div>
        </vdl-card-content>
    </vdl-card>

</div>