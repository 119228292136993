import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
  private readonly PREFERENCE_API_URL = environment.api_endpoint + 'preference';
  private currentPreference;
  constructor(private http: HttpClient) { }

  async get() {
    return await this.http.get(this.PREFERENCE_API_URL, { withCredentials: true }).toPromise();
  }

  async update(preference:any){
    const body = new FormData();
    body.append('email', preference.email);
    body.append('agreed_eula', preference.agreed_eula);
    body.append('skiped_intro', preference.skiped_intro);
    body.append('prefered_app', preference.prefered_app);
    await this.http.put(this.PREFERENCE_API_URL, body, { withCredentials: true }).toPromise();
  }
}
