<vdl-top-bar [trademark]="true" searchDisplayed="false">
  <vdl-icon vdl-top-bar-logo svgIcon="netinsights"></vdl-icon>
  <vdl-top-bar-title vdlTooltip="Unlock Key Portal">
    {{'topbar.titleL' | translate}} {{'topbar.titleR' | translate}}
  </vdl-top-bar-title>
  <vdl-top-bar-title-secondary>Unlock Key Portal</vdl-top-bar-title-secondary>

  <vdl-top-bar-actions>
    <vdl-menu #logoutMenu="vdlMenu">
      <button id="logoutBtn" vdl-tertiary-button (click)="logout()">
        {{'topbar.logout' | translate}}
      </button>
  
    </vdl-menu>
    <button vdl-icon-button [vdlMenuTriggerFor]="menu" class="app-menu" [disabled]="linkDisabled">
      <i class="fa fa-ellipsis-h"></i>
      <i class="fa fa-ellipsis-h block"></i> 
      <i class="fa fa-ellipsis-h"></i> 
    </button>
    <vdl-menu #menu="vdlMenu" >
      <button vdl-menu-item *ngFor="let app of apps" (click)="launch(app.endpoint, app.app_id)" [disabled]="app.isCurrent">
        <a vdl-button [ngClass]="{ 'current-app' : app.isCurrent }">{{ app.title }}</a>
        <vdl-icon *ngIf="app.isCurrent" fontSet="fontawesome" fontIcon="fa-check" class="app-default-icon"></vdl-icon>
      </button>
    </vdl-menu>
    <!--vdl-global-help (linkClicked)="globalHelpLinkClicked($event)">
      <vdl-global-help-header>
        <a vdl-focus-ring href=""> NetInsights<span>&trade;</span>Console</a>
        <a vdl-focus-ring href="{{ 'topbar.links.gettingStartedURL' | translate }}" target="_blank">
          {{ 'topbar.links.gettingStarted' | translate }}</a>
        <a vdl-focus-ring href="{{ 'topbar.links.adminGuideURL' | translate }}" target="_blank">
          {{ 'topbar.links.adminGuide' | translate }}</a>
      </vdl-global-help-header>
    </vdl-global-help-->
    <ng-template #helpLinks>   
      <vdl-popover-content class="nopadding">
        <div>
          
          <div *ngIf="user.isExternal === true">
            <a class="links nicHelp"  href="{{ 'topbar.helpLinks.nicHelpExternalURL' | translate }}" target="_blank">
            {{ 'topbar.helpLinks.nicHelp' | translate }}</a>
          </div>
          <div *ngIf="user.isExternal === false">
            <a class="links nicHelp"  href="{{ 'topbar.helpLinks.oatasHelpInternalURL' | translate }}" target="_blank">
            {{ 'topbar.helpLinks.oatasHelp' | translate }}</a>
          </div>
          <div>
            <a class="links"  vdl-focus-ring href="{{ 'topbar.helpLinks.legalNoticesURL' | translate }}" target="_blank">
                {{ 'topbar.helpLinks.legalNotices' | translate }}</a>
          </div>
          <div>
            <a class="links" dl-focus-ring href="{{ 'topbar.helpLinks.privacyPolicyURL' | translate }}" target="_blank">
                {{ 'topbar.helpLinks.privacyPolicy' | translate }}</a>
          </div>
          <div>
            <a class="links" vdl-focus-ring href="{{ 'topbar.helpLinks.userAgreementURL' | translate }}" target="_blank">
            {{ 'topbar.helpLinks.userAgreement' | translate }}</a>
          </div>
          <div>
            <a class="links" vdl-focus-ring href="{{ 'topbar.helpLinks.contactOatasUsURL' | translate }}" target="_blank">
            {{ 'topbar.helpLinks.contactUs' | translate }}</a>
          </div>
        </div>
      </vdl-popover-content>
    </ng-template>
    <button vdl-icon-button [vdlPopover]="helpLinks" class="app-menu" vdlPopoverPosition="bottom-right">
      <vdl-icon fontSet="fontawesome" fontIcon="fa-question"></vdl-icon>
    </button>

    <button id="user_icon" vdl-icon-button vdl-icon-button vdlPopoverTitle="{{'topbar.myProfile' | translate}}" [vdlPopover]="popContentBtn"  vdlPopoverPosition="bottom-right">
    <!--[vdlMenuTriggerFor]="logoutMenu"-->
      <div class="logout-circle">
        <span id="user_initial">{{userInitial}}</span>
      </div>
    </button>
    <ng-template #popContentBtn>     
      <div class="logoutPopover">
        <div class="logout-circle centerAlign" id='userInit'>
          <span>{{userInitial}}</span>
        </div>
        <div id="user_name">&nbsp;{{userName}}</div>
      </div>
      <div id="logoutBtn" class="signoutBtn" vdl-tertiary-button (click)="logout()">
          <vdl-icon fontSet="fontawesome" fontIcon="fa-sign-out" ></vdl-icon> {{ 'topbar.logout' | translate }}
      </div>
  </ng-template>
  </vdl-top-bar-actions>
</vdl-top-bar>
<vdl-progress-bar [mode]="'indeterminate'"
  [value]="50"
  [bufferValue]="75"
  [status]="'active'" *ngIf="loader" style="margin:0">
  </vdl-progress-bar>