import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AutoLogoutService } from './auto-logout.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OatasapiService {
  private ENDPOINT="";
  private BSK_ENDPOINT=this.ENDPOINT + "/bsk";
  private SSK_ENDPOINT=this.ENDPOINT + "/ssk";
  private SEARCH_API: string;

  constructor(
    private _autoLogout:AutoLogoutService,
    private http: HttpClient) {

     }

  async BuildSecureKey(payload: any) {
    const isExternalUser = sessionStorage.getItem("isExternalUser");
    if (isExternalUser.toUpperCase() === "TRUE") {
      this.ENDPOINT = environment.external_skpapi_endpoint;
      this.BSK_ENDPOINT = this.ENDPOINT + "/bsk";
    }
    return await this.http.post(this.BSK_ENDPOINT, payload, { withCredentials: true }).toPromise();
  }

  async SendSecureKey(payload : string ) {
    const isExternalUser = sessionStorage.getItem("isExternalUser");
    if (isExternalUser.toUpperCase() === "TRUE") {
      this.ENDPOINT = environment.external_skpapi_endpoint;
      this.SSK_ENDPOINT = this.ENDPOINT + "/ssk";
    }
    return await this.http.get(this.SSK_ENDPOINT, { withCredentials: true }).toPromise();
  }

  public postRequest(url: string, requestObj: any, requestOptions: any) {
    if (requestOptions) {
      return this.http.post(url, requestObj, requestOptions);
    } else {
      return this.http.post(url, requestObj);
    }
  }


}
