<via-portal-top-bar></via-portal-top-bar>

<div class="app-body">
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</div>
<cookie-law  class="alignCenter"  position="bottom" name='cookieKeyforLaw'  target='_blank' expiration="1">
  {{ 'cookie-banner.cooke-law-message' | translate }}
  <a  target="_blank" href="{{ 'cookie-banner.privacyPolicyURL' | translate }}"><span class="cookieMoreLink">{{ 'cookie-banner.read-more' | translate }}</span></a>
</cookie-law> 
