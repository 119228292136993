<vdl-notification id="VNT1" #vdlNotification1 style="z-index: 2000; width: 60%;"></vdl-notification>
<div class="terminal" >
   <!-- <button  class="material-icons clear-button" 
        title="Clear screen content" (click)='clearScreen()'>
        <mat-icon svgIcon="restore_from_trash"></mat-icon>
   </button> -->
   <button  class="send-button"   
        title="Send security key to an email" (click)='sendSecuritykey()'>
        <vdl-icon fontSet="fontawesome" fontIcon="fa-envelope"></vdl-icon>
   </button>
   <button  class="copy-button" 
        title="Copy security key to clipboard" (click)='copySecuritykey()'>
        <vdl-icon fontSet="fontawesome" fontIcon="fa-copy"></vdl-icon>
   </button>
   <textarea cols="77" rows="20" readonly="true" >{{testSK}}</textarea>
   <!--<pre class="error" *ngSwitchCase="messageTypes.Error"> {{ tipMessage }} </pre>-->
   <div>
</div>
<div id="prebootstrap-overlay" name="po-terminal" [hidden]="!showProgress" style="opacity: .8;">
     <div id="prebootstrap-loader"></div>
 </div>
 