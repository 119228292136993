import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { languageMapping } from '../app/language-mapping-utils';
import { ProfileService } from './service/profile.service';
import { AutologoutPopupComponent } from './autologout-popup/autologout-popup.component';
import * as _moment from 'moment';
const moment = _moment;
import { AutoLogoutService } from './service/auto-logout.service';
import { VdlDialog, VdlDialogConfig, VdlDialogRef } from '@vdlx/vdl-angular/dialog';
import { WSCloseEvent, WSMessageEvent } from './service/type';
import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { AppService } from './service/app.service';
@Component({
  selector: 'via-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'oatas-portal';
  public dialogOpen = false;
  public activityEvents = [];

  public inactiveSec = -1;
  public expirySec = -1;

  //cookie
  public inactiveTimeout = '';
  public inactiveTimestamp = null;
  public gracePeriod='';
  public cookieExpiration = null;

  //
  public timeoutType = '';
  public titleBlinkTimer;
  public NICtitle = 'Veritas NetInsights Console';
  public popupState= null;
  public loggedOut= '';
  public reLogin= '';
  public closeDone= '';

  public dialogRef: VdlDialogRef<AutologoutPopupComponent>;
  lastCloseResult: string;
  public config: VdlDialogConfig = {
    disableClose: false,
    width: '500px',
    height: '',
    autoFocus: false,
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
    }
  };

  constructor(
    public dialog: VdlDialog,
    private _autoLogoutSvc: AutoLogoutService,
    private translate: TranslateService,
  ) {
    document.addEventListener('visibilitychange', this.checkDialogStatus.bind(this));
    this.activityEvents = [
        'mousedown', 'mousemove', 'keydown',
        'scroll'
      ];
    //user action
    this.addEvents();
  }

  ngOnInit() {
    const profileLang = languageMapping(this.translate.getBrowserCultureLang());
    this.translate.setDefaultLang('en-US');
    this.translate.use(profileLang);

    //order is important
    this.getAllCookie();

    if (this.loggedOut !== '' || this.reLogin !== '') {
        this.removeCookie('LOGGED_OUT');
        this.loggedOut = '';
        this.reLogin = '';
    }
    if (this.popupState === 'open' && this.dialogOpen === false) {
        this.putCookie('POPUP_STATUS', 'close');
    }
    if (this.popupState === 'close') {
        this.removeCookie('TIMER');
        this.removeCookie('POPUP_STATUS');
        this.popupState = '';
    }
    //timestamp update for inactivity
    if (this.inactiveTimestamp !== null && this.gracePeriod !== '' && this.inactiveTimeout !== '') {
        setInterval(() => {
          this.inactivityUpdates();
      }, 10000);
    }
}
  
  public inactivityUpdates() {
    this.getAllCookie();

    this.timestampCalc(this.inactiveTimestamp, 'inactive');

    if (this.inactiveSec >= 20) {
      this.removeCookie('RE_LOGIN');
      this.getAllCookie();
      this.reLogin = '';
    }
    if (this.inactiveSec > 60 && this.popupState !== null) {
      this.removeCookie('TIMER');
      this.removeCookie('POPUP_STATUS');
    }
    this.checkUserLog();

    if (this.popupState === 'close' && this.dialogOpen === true) {
      document.title = this.NICtitle;
      this.onDialogClose();
    }
    
    const remaining = parseInt(this.inactiveTimeout, 10) - parseInt(this.gracePeriod, 10);
    /* Due to upperTimeLimit I am avoiding to show both popup together.
        Instead of having fixed upper limit 540 making this dynamicn as per 
        grace period */
    const upperTimeLimit = (300 + parseInt(this.gracePeriod, 10));
    if (this.inactiveSec >= remaining && this.dialogOpen === false && this.expirySec >= upperTimeLimit) {
          this.timeoutType = 'inactive';
          this.openPopupConfig('Inactive Timeout', 'inactive');
    }

      if (this.cookieExpiration !== null) {
        this.timestampCalc(this.cookieExpiration, 'expire');
        if (this.expirySec <= 300 && this.expirySec > 0 && this.dialogOpen === false && this.closeDone === '') {
          this.removeCookie('RE_LOGIN');
          this.reLogin = '';
          this.closeDone = 'done';
          this.timeoutType = 'expiration';
          this.openPopupConfig('Session Timeout', 'expiration');
        }
        if (this.expirySec <= -1) {
          this.closeDone = '';
          this.reLogin = '';
          this.removeCookie('RE_LOGIN');
          this._autoLogoutSvc.logout();
        }
      }
  }

  private timestampCalc(timestamps, whichType){
    const date = moment();
    const ts = moment(Number(timestamps)*1000).toDate();
    const ping = moment(ts);
    if (whichType === 'inactive') {
      this.inactiveSec = date.diff(ping, 'seconds');
    } else {
      this.expirySec = ping.diff(date, 'seconds');
    }
  }

  

  private onDialogClose() {
    this.dialogOpen = false;
    if (this.dialogRef !== null && this.dialogRef !== undefined) {
      this.dialogRef.close();
    }
  }

  public userAction() {
    if (this.inactiveTimestamp !== null && this.gracePeriod !== '' && this.inactiveTimeout !== '') {
      const date = moment().unix();
      this.putCookie('NIC-LAST-ACTIVITY', String(date));
      this.getAllCookie();
    }
  }

  private addEvents(){
    this.activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, this.userAction.bind(this));
    });
  }

  private removeEvents() {
    this.activityEvents.forEach((eventName) => {
      document.removeEventListener(eventName, this.userAction.bind(this));
    });
  }

  private openPopupConfig(title, type) {
    this.dialogOpen = true;
    this.putCookie('POPUP_STATUS', 'open');
    this.getAllCookie();
    this.titleBlinkTimer = setInterval(() => { // blinking 
      if (this.popupState === 'open') {
        document.title = document.title === this.NICtitle ? title : this.NICtitle;
      }
    }, 500);
    this.timeoutType = type;
    this.openBasicDialog(type);     
  }

  public openBasicDialog(popupType) {
    //remove user action
    this.removeEvents();
    //popup setting
    this.config.data.type = popupType;
    this.dialogRef = this.dialog.open(AutologoutPopupComponent, this.config);

    //after close
    this.dialogRef.afterClosed().subscribe((result: string) => {
        //adding user action
        this.addEvents();
        this.dialogOpen = false;
        this.lastCloseResult = result;
        this.dialogRef = null;
        const date = moment().unix();
        this.putCookie('NIC-LAST-ACTIVITY', String(date));
        this.putCookie('POPUP_STATUS', 'close');
        this.removeCookie('TIMER');
        this.getAllCookie();
        clearInterval(this.titleBlinkTimer);
        document.title = this.NICtitle;
    });
  }

  public checkDialogStatus(){
    this.getAllCookie();
    if (this.popupState === 'close' && this.dialogOpen === true) {
      this.onDialogClose();
    }
    if (this.popupState === 'open' && this.dialogOpen === false) {
        this.dialogOpen = true;
        this.titleBlinkTimer = setInterval(() => { 
          if (this.timeoutType === 'inactive') {
            document.title = document.title === this.NICtitle ? "Inactive Timeout" : this.NICtitle;
          } else {
            document.title = document.title === this.NICtitle ? "Session Timeout" : this.NICtitle;
          }
        }, 500);
        this.openBasicDialog(this.timeoutType);
    }
    this.checkUserLog();
  }

  private checkUserLog() {
    if (this.loggedOut === 'true') {
      this.onDialogClose();
      this._autoLogoutSvc.logout();
    }
    if (this.reLogin === 'true' && this.dialogOpen === true) {
      this.onDialogClose();
      this._autoLogoutSvc.reLogin();
    }
  }
  

  public getAllCookie(){
    const cookie = this._autoLogoutSvc.getAllCookie();
    for (const key in cookie) {
      if (cookie.hasOwnProperty(key)) {
        switch (key) {
          case 'NIC-INACTIVITY' :
            this.inactiveTimeout = cookie[key];
            break;
          case 'NIC-LAST-ACTIVITY' :
            this.inactiveTimestamp = cookie[key];
            break;
          case 'NIC-GRACE-PERIOD' :
            this.gracePeriod = cookie[key];
            break;
          case 'NIC-JWT-EXPIRY' :
              this.cookieExpiration = cookie[key];
              break;
          case 'POPUP_STATUS' :
              this.popupState = cookie[key];
              break;
          case 'LOGGED_OUT' :
              this.loggedOut = cookie[key];
              break;
          case 'RE_LOGIN' :
              this.reLogin = cookie[key];
              break;
          default :
             break;
        }
      }
      
    }     
  }

  public removeCookie(key: string){
    this._autoLogoutSvc.removeCookie(key);
  }

  public putCookie(key: string, value: string){
    this._autoLogoutSvc.putCookie(key, value);
  }
}
