import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ResolveEnd } from '@angular/router';
import { EMPTY } from 'rxjs';
import { environment } from '../../environments/environment';
import { ErrorPageMessage } from '../errorpage/error-page-message';
import { AppAuthenticatorService } from '../service/app-authenticator.service';
import { AppService } from '../service/app.service';
import { PreferenceService } from '../service/preference.service';
import { ProfileService } from '../service/profile.service';

@Injectable()
export class RouteGuardService implements CanActivate {
    constructor(
        public router: Router,
        private _appService : AppService)
        { }
    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
            return new Promise((resolve) => {

                this._appService.getAppsBrife().then((resp: any) => {
                    if (resp == undefined) {
                        console.log("response error.");
                        resolve(false);
                        return ;
                    }
                    const apps = resp['apps'];
                    if (apps == undefined || apps.length == 0) {
                        console.log("empty apps.");
                        resolve(false);
                        return ;
                    }
                    /*let app = apps.filter( (a) => a.app_id == environment.app_id);
                    if ( app == undefined || app.length == 0) {
                        console.log("no specified application.");
                        resolve(false);
                        var epm = new ErrorPageMessage(
                            "error-page.title.accessDenied",
                            "error-page.content.accessDenied", new Error(JSON.stringify(apps)));
                        this.router.navigateByUrl("/errorpage");
                        sessionStorage.setItem("errorPageError", JSON.stringify(epm));
                        return;
                    }
                    if (app.length != 1) {
                        console.log("many specified application ", app);
                        resolve(false);
                        var epm = new ErrorPageMessage(
                            "error-page.title.applicationError",
                            "error-page.content.applicationIDError");
                        this.router.navigateByUrl("/errorpage");
                        sessionStorage.setItem("errorPageError", JSON.stringify(epm));
                        return;
                    }*/
                    this.parseQueryParams();
                    resolve(true);
                })
                .catch( err => {
                    console.log("canActive promise catch error ", err);
                });
            });
          }
    private parseQueryParams() {
        // Save query params from url to sessionStorage, using to autofill to Appliance UID
        if (location.search.length != 0) {
            console.log("canActivate: " + location.search);
            var params_string = location.search.substring(1);
            console.log("canActivate: params_string is " + params_string);
            var params_list = params_string.split("&");
            console.log("canActivate: params_list is " + params_list);
            for (var i = 0; i < params_list.length; i++) {
                var param = params_list[i];
                var key_value = param.split("=");
                var key = key_value[0];
                var value = key_value[1];
                console.log("canActivate: key is " + key + ",  value is " + value);
                sessionStorage.setItem(key, value);
            }
        }
    }
}
