<vdl-notification #vdlNotification style="z-index: 2000;"></vdl-notification>
<div class="dashboard">
  <div class="app-title" >
    <div class="welcome">
      <p class="welcome-title">
        {{'dashboard.title' | translate}}
      </p>
      <p class="welcome-subtitle">
        {{'dashboard.sub-title' | translate}} 
      </p>
    </div>

  </div>

  <div class="app-title" style="background-color: #ccc; padding: 20px;" *ngIf="length === 0">
    <div class="welcome">
      <p class="welcome-title">
        {{'dashboard.mess-portal' | translate}}
      </p>
    </div>
  </div>

  <div class="app-title" style="background-color: #ccc; padding: 20px;" *ngIf="length > 0 && applications && applications.length === 0">
    <div class="welcome">
      <p class="welcome-title">
        {{'dashboard.mess-apps' | translate}}
      </p>
    </div>
  </div>
  <vdl-progress-bar [mode]="'indeterminate'"
  [value]="50"
  [bufferValue]="75"
  [status]="'active'" *ngIf="loader" style="margin:0;"> <!-- removed to hide white space visible due to cloud background-->
  </vdl-progress-bar>


  <div class="app-container">
    <via-portal-app-payload></via-portal-app-payload>
  </div>
</div>
