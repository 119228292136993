<vdl-dialog-header>
  <vdl-dialog-title>
      <div *ngIf="!expirationPopup">Inactive User Timeout <span class="time">Timeleft: {{timeout}}</span></div>
      <div *ngIf="expirationPopup">Session Timeout <span class="time">Timeleft: {{timeout}}</span></div>
  </vdl-dialog-title>
</vdl-dialog-header>
<vdl-dialog-content>
  <div *ngIf="!expirationPopup">
    You are inactive from long time. Please press continue to keep session alive or Hit Logout. (If no action taken you will logged out in 5 mins)
  </div>
  <div *ngIf="expirationPopup">
    Your session will expire in 5 mins. Kindly hit continue to save your work OR Logout OR Re-Login.
  </div>
</vdl-dialog-content>
<vdl-dialog-actions>
  <vdl-button-bar>
   
    <button vdl-primary-button (click)="continue()">
      Continue
    </button>
    <button vdl-primary-button (click)="logout()">
      Logout
    </button>
    <button vdl-primary-button (click)="reLogin()" *ngIf="expirationPopup">
      Re-Authenticate
    </button>
  </vdl-button-bar>
</vdl-dialog-actions>
