import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
//import { VdlIconRegistry } from '@vdlx/vdl-angular/icon';
import { VdlDialog, VdlDialogConfig, VdlDialogRef } from '@vdlx/vdl-angular/dialog';
import { AppService } from '../service/app.service';
import {
  NotificationStates,
  NotificationTypes,
  VdlNotification
} from '@vdlx/vdl-angular/notification';
import { NotificationApiService } from '../service/notification-api.service';
import * as _moment from 'moment';
const moment = _moment;
import { PreferenceService } from '../service/preference.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalComponent } from '../service/error-modal/error-modal.component';

@Component({
  selector: 'via-portal-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('vdlNotification', { static: true })
  private notification: VdlNotification;

  @ViewChild('vdlSecondNotification', { static: true })
  private notificationS: VdlNotification;

  public dialogRef: VdlDialogRef<ErrorModalComponent>;
  lastCloseResult: string;
  public config: VdlDialogConfig = {
    disableClose: true,
    width: '500px',
    height: '',
    autoFocus: false,
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
    }
  };
  public loader;
  public defaultEndPoint = '';
  public applications;
  public currentAlert;
  public notifyData;
  public length;
  constructor(
    //vdlIconRegistry: VdlIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private appService: AppService,
    private notify: NotificationApiService,
    private preferenceService:PreferenceService,
    private translate: TranslateService,
    private dialog : VdlDialog
  ) {
  }
  ngOnInit(): void {
    this.notify.getAllNotification().then((data) => {
        if (data == undefined) {
          return;
        }
        const notifyData = data['notifications'];
        this.notifyData = notifyData.filter((ndata) => ndata.id !== 'NetInsights');
        this.notifyData.map((n) => {
          if(n.message_content.length >= 200 && n.id !== 'NetInsights'){
              n.message_content = n.message_content.substr(0, 200) + '...'
          }
        });
        this.notifyData = this.timeoutCalc(this.notifyData);
    });

    this.notify.getNotification('NetInsights').then((data) => {
        if (data == undefined) {
          return;
        }
      let notifyPortal = data['notifications'];
      if (notifyPortal && notifyPortal.length > 0) {
        notifyPortal = this.timeoutCalc(notifyPortal);
        const isExpired = notifyPortal.filter((n) => n.expired === false);
        if (isExpired.length > 0) {
          this.sendNotification(notifyPortal);
        }
      }
  });

  }

  private timeoutCalc(notifyData) {
    notifyData.map((n) => {
      const str = moment().format('YYYY-MM-DD HH:m:s');
      const date = Date.parse(str)/1000;

      //Notification will disappear after end time
      if (date >= n.end_time) {
        n.expired = true;
      } else {
        n.expired = false;
      }
      //Notification will appear once start time
      if (date >= n.start_time){
        n.visible = true;
      }
    });
    return notifyData;
  }

  private openBasicDialog(mess) {
    this.dialogRef = this.dialog.open(ErrorModalComponent, this.config);
    this.config.data.message = mess;
    this.dialogRef.afterClosed().subscribe((result: string) => {
      this.lastCloseResult = result;
      this.dialogRef = null;
    });
  }


  public launch(endPoint: string, id: string, ) {
    if (endPoint && id) {
      this.loader = true;
      this.appService.getHealthCheck(id).then((res) => {
        if (res && res['status'] === 200) {
          window.location.replace(endPoint);
          this.loader = false;
        } else {
          this.openBasicDialog(this.translate.instant('dashboard.err-message'));
          this.loader = false;
        }
      }).catch((e) => {
        console.error(e);
        this.openBasicDialog(this.translate.instant('dashboard.err-message'));
        this.loader = false;
      })
    }
  }

  public setDefault(endPoint: string) {
    this.defaultEndPoint = endPoint;
    this.notification.closeAll();
    this.notification.setNotification(
      this.translate.instant('dashboard.notification', { value: this.defaultEndPoint }),
      // `You have selected ${this.defaultEndPoint} as your default application, Next time you will be directed to the selected ${this.defaultEndPoint}`,
      NotificationTypes.Persistent,
      NotificationStates.Info,
      "",
      3000);
    this.notificationS.closeAll();
  }

  public goTo(route: string) {
    this.router.navigateByUrl(route);
  }

  private sendNotification(messages: any[]) {
    let list = '';
    let notificationState;
    if (messages && messages.length > 0) {
        let notificationType = NotificationTypes.Persistent;
        if (messages.length === 1) {
          messages.forEach((m, i) => {
            if (m.id === 'NetInsights' && !m.expired && m.visible === true) {
               list += ' ' + m.message_content ;
            }
          });
        } else {
          messages.forEach((m, i) => {
            if (m.id === 'NetInsights' && !m.expired && m.visible === true) {
               list += ' ' + m.message_content + ' '+'|' ;
            }
          });
        }
        messages.find((mess) => {
          if (mess.id === 'NetInsights' && !mess.expired && mess.visible === true) {
            if (mess.notification_type === 'information') {
              notificationState = NotificationStates.Info;
            } else if (mess.notification_type === 'warning') {
              notificationState = NotificationStates.Warning;
            } else if (mess.notification_type === 'critical') {
              notificationState = NotificationStates.Error;
            } else {
              notificationState = NotificationStates.Default;
            }
          }
        });

        if (messages[0].notification_type) {
            const key = messages[0].notification_type as keyof typeof NotificationTypes;
            if (key) {
                notificationType = NotificationTypes[key];
            }
        }

        if (messages[0].notification_state) {
            const key = messages[0].notification_state as keyof typeof NotificationStates;
            if (key) {
                notificationState = NotificationStates[key];
            }
        }
        if (list.length > 0 ) {
          this.currentAlert = messages[0];
          this.notification.setNotification(
              list,
              notificationType,
              notificationState,
              '',
              messages[0].notification_timeout || 3000
          );
        }
    }

}

}