<vdl-dialog-header>
    <vdl-dialog-title>Application Error</vdl-dialog-title>
  </vdl-dialog-header>
  <vdl-dialog-content>
    <p style="font-size: 1rem; text-align: center;">{{message}}</p>
  </vdl-dialog-content>
  <vdl-dialog-actions>
    <vdl-button-bar>
      <button
        vdl-tertiary-button
        vdl-dialog-close
        (click)="dialogRef.close('Dismiss')"
      >
        Close
      </button>
    </vdl-button-bar>
  </vdl-dialog-actions>