import { Component, OnInit } from '@angular/core';
import { environment } from "../../environments/environment";
import { AutoLogoutService } from "../service/auto-logout.service";
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'via-portal-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  private readonly LOGOUT_API_URL = environment.api_endpoint + 'logout';

  constructor(
    private http: HttpClient,
    private _autoLogout: AutoLogoutService,    
    ) { }
  public signedOut = false;
  ngOnInit() {
    this.http.get(this.LOGOUT_API_URL, { withCredentials: true }).toPromise().then(resp => {
      this._autoLogout.removeCookie("OATASSK");
      this._autoLogout.removeCookie("OATASPL");
      const isExternalUser = sessionStorage.getItem("isExternalUser");
      let sigoutUrl = environment.logout_internal_url;
      if (isExternalUser.toUpperCase() === "TRUE") {
          sigoutUrl = environment.logout_external_url;
      }
      sessionStorage.clear();
      this.signedOut = true;
     setTimeout(() => { window.location.replace(sigoutUrl) }, 1000);
    })
  }

}
