import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { unescapeIdentifier } from '@angular/compiler';
import { ErrorPageMessage } from './errorpage/error-page-message';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService:CookieService,
    private router: Router) { }
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const t0 = performance.now();
    //console.log("intercept ", request, next);
    return next.handle(request).pipe(catchError((err: any) => {
      console.log("http intercept error ", err);
      const t1 = performance.now();
      console.log(`API ${request.url}, took ${(t1 - t0) / 1000} seconds`);
      let epm = new ErrorPageMessage(
          'error-page.title.networkerror',
          'error-page.content.networkerror',
          err
      );
      if (err instanceof HttpErrorResponse) {
            /* If 404 error, safar return httpErrorResponse status 0
            If missing JWK, 
              Safar => HttpErrorResponse
                    error: XMLHttpRequestProgressEvent {isTrusted: true, position: 0, totalSize: 0, lengthComputable: false, loaded: 0, …}
                    headers: HttpHeaders {normalizedNames: Map, lazyUpdate: null, headers: Map}
                    message: "Http failure response for https://api-cdev.netinsights.veritas.com/profile: 0 Unknown Error"
                    name: "HttpErrorResponse"
                    ok: false
                    status: 0
                    statusText: "Unknown Error"
                    url: "https://api-cdev.netinsights.veritas.com/profile"
              Chrome => HttpErrorResponse
                    error: "Cannot decode the session"
                    headers: HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, lazyInit: ƒ}
                    message: "Http failure response for https://api-cdev.netinsights.veritas.com/profile: 401 OK"
                    name: "HttpErrorResponse"
                    ok: false
                    status: 401
                    statusText: "OK"
                    url: "https://api-cdev.netinsights.veritas.com/profile"
            */
            const csrf = this._cookieService.get("NETINSIGHTS-BACKEND-CSRF");
            if (csrf == undefined || csrf.length == 0) {
              //console.log("crsf", csrf);
              epm = new ErrorPageMessage(
                    "error-page.title.authentication",
                    "error-page.content.authentication",
                    err);
                    //console.log("throw");
                    //throwError(err);
            }else{
              if ( err.status === 401 || err.status === 403 ) {
                    epm = new ErrorPageMessage(
                    "error-page.title.accessDenied",  
                    "error-page.content.accessDenied",
                    err);
              } 
            }
            

       }
       sessionStorage.setItem("errorPageError", JSON.stringify(epm));
       this.router.navigateByUrl("/errorpage");                    
       return EMPTY;
    }))};
}
