import { Injectable, resolveForwardRef } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

class app {
  app_id : string;
  status : string;
}
class apps {
  apps : app[];
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private readonly APP_API_URL_BRIEF = environment.api_endpoint + 'appbriefinfolist';
  private readonly APP_API_URL_DETAIL = environment.api_endpoint + 'appdetailinfolist';
  private readonly HEALTH_API = environment.api_endpoint + 'healthcheck';

  constructor(private http: HttpClient) { }

  async getApps() {
    return await this.http.get(this.APP_API_URL_DETAIL, { withCredentials: true }).toPromise();
  }

  async getAppsBrife() {
    return await this.http.get(this.APP_API_URL_BRIEF, { withCredentials: true }).toPromise();
  }

  async getHealthCheck(id) {
    return await this.http.get(`${this.HEALTH_API}?id=${id}`, { withCredentials: true, observe: 'response'}).toPromise();
  }

}
