import { Component, OnInit, Inject } from '@angular/core';
import {
  VDL_DIALOG_DATA,
  VdlDialogRef,
} from '@vdlx/vdl-angular/dialog';

@Component({
  selector: 'via-portal-error-modal',
  templateUrl: './error-modal.component.html',
})
export class ErrorModalComponent implements OnInit {
  public message;
  constructor(
    public dialogRef: VdlDialogRef<ErrorModalComponent>,
    @Inject(VDL_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.message = this.data.message;
  }

}
