import { Component, OnInit,Input,ViewChild,Inject} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { CopierService } from '../service/copier.service';
import { VdlIconRegistry } from '@vdlx/vdl-angular/icon';
import { TranslateService } from '@ngx-translate/core';
import {
  NotificationStates,
  NotificationTypes,
  VdlNotification
} from '@vdlx/vdl-angular/notification';
import { OatasapiService } from '../service/oatasapi.service';
import { DOCUMENT } from '@angular/common';
import { AutoLogoutService } from "../service/auto-logout.service";
import { environment } from '../../environments/environment';
import { WSCloseEvent, WSMessageEvent } from '../service/type';

@Component({
  selector: 'via-portal-app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit {
  @Input() testSK : string ;
  @Input() appliancesn: string;
  @ViewChild('vdlNotification1', { static: true })
  private notification: VdlNotification;
  public showProgress = false;
  private _skpws = null;
  constructor(
    @Inject(DOCUMENT) private document: any,
    vdlIconRegistry: VdlIconRegistry,
    private oatasApi: OatasapiService,
    private translate: TranslateService,
    private _autoLogout: AutoLogoutService,
    private sanitizer: DomSanitizer
    ) {
      vdlIconRegistry.registerFontClassAlias('fontawesome', 'fa');
      vdlIconRegistry.addSvgIcon(
        'email',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/email.svg')
      );
      vdlIconRegistry.addSvgIcon(
        'content_copy',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/content_copy.svg')
      );

  }

  ngOnInit(): void {}

  public copySecuritykey() {
    // Clear existing notification
    if (this.notification != null){
      console.log("close notification", this.notification)
      this.notification.closeAll();
    }
    const cp = new CopierService();
    if (cp.copyText(this.testSK)) {
      this.notification.setNotification(
        this.translate.instant("pay-load.copy-secure-key.success"),
        NotificationTypes.Transient,
        NotificationStates.Success,
        "",
        3000
      );
    }else{
      this.notification.setNotification(
        this.translate.instant("pay-load.copy-secure-key.failure"),
        NotificationTypes.Transient,
        NotificationStates.Error,
        "",
        3000
      );
    }

  }

  private onConnectionOpen(ws :any): void {
    console.log("supportkey onConnectionOpen ", ws);
    console.log("appliancesn is", this.appliancesn);
    var payload = {
      action: 'sendUnlockKey',
      email: sessionStorage.getItem("profileEmail"),
      unlockKey: this.testSK,
      applianceuid: this.appliancesn
    }
    console.log("sendUnlockKey payload btoa=" + JSON.stringify(payload));
    ws.send(JSON.stringify(payload));
  }

  public onConnectionMessage(evt: MessageEvent) {
    console.log("Send unlock key return event: ", evt);
    this.showProgress = false;
    let wsData = JSON.parse(evt.data);
    if (wsData["result"] == "success") {
      this.notification.setNotification(
        this.translate.instant("pay-load.send-secure-key.success") + sessionStorage.getItem("profileEmail"),
        NotificationTypes.Transient, NotificationStates.Success,
        "", 3000);
    } else {
      this.notification.setNotification(
        this.translate.instant("pay-load.send-secure-key.failure") + sessionStorage.getItem("profileEmail"),
        NotificationTypes.Persistent,NotificationStates.Error,
        "",3000 );
    }
  }

  private onConnectionClose(_evt?: WSCloseEvent): void {
    console.log("Send Unlock Key websocket onConnectionClose", _evt);
    // this.showProgress = false;
  }

  public onConnectionError(evt?: any) {
    this.showProgress = false;
    console.log("Send unlock key error: ", evt);
  }

  public sendSecuritykey() {
    // Clear existing notification
    if (this.notification != null){
      console.log("close notification", this.notification)
      this.notification.closeAll();
    }
    this.showProgress = true;
    if (this._skpws !=  null) {
      this._skpws.close();
      this._skpws = null;
    }
    this._skpws = new WebSocket(environment.skpws_endpoint);
    this._skpws.onopen = this.onConnectionOpen.bind(this, this._skpws);
    this._skpws.onmessage = this.onConnectionMessage.bind(this);
    this._skpws.onclose = this.onConnectionClose.bind(this);
    this._skpws.onerror = this.onConnectionError.bind(this);
    // this.oatasApi.SendSecureKey(this.testSK).then(
    //      data => {
    //        if (data.hasOwnProperty("statusCode")) {
    //         this.showProgress = false;
    //         switch (data["statusCode"]) {
    //            case "V-601-1-138":
    //                 const em = sessionStorage.getItem("profileEmail");
    //                 this.notification.setNotification(
    //                   this.translate.instant("pay-load.send-secure-key.success") + em,
    //                   NotificationTypes.Transient, NotificationStates.Success,
    //                   "", 3000);
    //                 return
    //             case "V-601-1-101":
    //             case "V-601-1-101":
    //             case "V-601-1-130":
    //             case "V-601-1-131":
    //             case "V-601-1-132":
    //             case "V-601-1-141":
    //               this.notification.setNotification(
    //                 this.translate.instant("pay-load.error.netinsight.authentication"),
    //                 NotificationTypes.Persistent,NotificationStates.Error,
    //                 data["statusCode"],3000 );
    //               return;
    //             default:
    //               this.notification.setNotification(
    //                 this.translate.instant("pay-load.error.general.tryagain"),
    //                 NotificationTypes.Persistent, NotificationStates.Error,
    //                 data["statusCode"],3000 );
    //               return;
    //          }//end switch
    //        }//end if
    //        this.showProgress = false;
    //        this.notification.setNotification( data.toString(),
    //         NotificationTypes.Persistent, NotificationStates.Error,
    //         this.translate.instant("pay-load.error.general.network"),3000);
    //      })
    //     .catch(e => {
    //       //HttpErrorResponse
    //       //console.log("error ", e);
    //       this.showProgress = false;
    //       this.notification.setNotification( JSON.stringify(e) ,
    //       NotificationTypes.Persistent, NotificationStates.Error,
    //       this.translate.instant("pay-load.error.general.excepion"),3000);
    //     });
 }

  public clearScreen() {
    this.copySecuritykey();
  }
}

