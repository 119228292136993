// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  session_internal_url:  'https://api-prod.netinsights.veritas.com/saml/session',
  session_external_url: 'https://vam.veritas.com/VAM/HeaderInfo',
  login_url: 'https://vam.veritas.com/VAM/index.jsp?returnURL=https://api-prod.netinsights.veritas.com/session',
  api_endpoint: 'https://api-prod.netinsights.veritas.com/',
  logout_internal_url: 'https://myapps.microsoft.com/',
  logout_external_url: 'https://vam.veritas.com/VAM/processLogout.do?returnURL=http://my.veritas.com/webapp/',
  app_id: '15EADCEC-4FEC-E562-4273-B082EB294DDC',
  netinsight_console_url: 'https://netinsights.veritas.com',
  internal_skpapi_endpoint: 'https://api-oatas.netinsights.veritas.com',
  external_skpapi_endpoint: 'https://api.oatas.veritas.com',
  skpws_endpoint: "wss://api-ws.oatas.veritas.com/Prod",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
