<vdl-notification #vdlNotification style="z-index: 2000; width: 100%;"></vdl-notification>
<div id="auth-prebootstrap-overlay" name="authcheck" [hidden]="authDone" style="transition: none">
    <div id="prebootstrap-loader"></div>
</div>
<div class="page-body page-body-panel-noBottom" [hidden]="!otpCheckDone">
  <form [formGroup]="payloadForm">
      <div class="row">
          <div class="col-xs-4 margin-l20 margin-t50">
              <vdl-form-field class="container" label="{{ 'pay-load.details.applianceSN' | translate }}">
                  <vdl-suffix [iconError]="isInvalid(payloadForm.get('applianceSN'))"></vdl-suffix>
                  <input type="text" vdlInput class="width-99" name="applianceSN" id="applianceSN" formControlName="applianceSN" required
                      [(ngModel)]="userInputs.appliancesn" autofocus autocomplete="off" (blur) = "!isExternalUser && ApplianceSNLBlur()"
                         [readonly]="isExternalUser ? 'readonly' : null"
                         [style.background-color]="isExternalUser ? 'lightgray' : 'transparent'"
                  >
                  <vdl-error *ngIf="payloadForm.get('applianceSN').invalid">{{ getValidatorError("applianceSN") | translate }}</vdl-error>
              </vdl-form-field>
              <vdl-form-field *ngIf="!isExternalUser" class="container" label="{{ 'pay-load.details.oneTimePassword' | translate }}">
                  <vdl-suffix [iconError]="isInvalid(payloadForm.get('oneTimePassword'))"></vdl-suffix>
                  <input vdlInput class="width-99" name="oneTimePassword" formControlName="oneTimePassword" [(ngModel)]="userInputs.otp" autocomplete="off">
                  <vdl-error *ngIf="payloadForm.get('oneTimePassword').invalid">{{ getValidatorError("oneTimePassword") | translate }}</vdl-error>
              </vdl-form-field>
              <vdl-form-field class="container" label="{{ 'pay-load.details.password' | translate}}">
                  <vdl-suffix [iconError]="isInvalid(payloadForm.get('password'))" [iconPassword]="true"></vdl-suffix>
                  <input vdlInput required class="width-99" type="password" name="password" (copy)="$event.preventDefault()" (cut)="$event.preventDefault()" (paste)="$event.preventDefault()"
                  (keydown.space)="$event.preventDefault()" (input)="onPassphraseChanged()"
                  formControlName="password" [(ngModel)]="userInputs.passphrase" autocomplete="new-password">
                  <vdl-error *ngIf="payloadForm.get('password').invalid">{{getValidatorError("password")  | translate }}</vdl-error>
              </vdl-form-field>
              <vdl-form-field class="container" label="{{ 'pay-load.details.confPassword' | translate}}">
                  <vdl-suffix [iconError]="isInvalid(payloadForm.get('confirm'))" [iconPassword]="true"></vdl-suffix>
                  <input vdlInput required class="width-99" type="password" name="confirm" (copy)="$event.preventDefault()" (cut)="$event.preventDefault()" (paste)="$event.preventDefault()"
                  (keydown.space)="$event.preventDefault()" (input)="onPassphraseChanged()"
                  formControlName="confirm" [(ngModel)]="_confirm" autocomplete="off">
                  <vdl-error *ngIf="payloadForm.get('confirm').invalid">{{getValidatorError("confirm") | translate}}</vdl-error>
              </vdl-form-field>
              <vdl-form-field class="container" label="{{ 'pay-load.details.reason4unlock' | translate}}">
                <vdl-suffix [iconError]="isInvalid(payloadForm.get('reason4unlock'))"></vdl-suffix>
                <div class="remaining-length-wrapper">
                  <textarea [required]="isReasonRequired" vdlInput name="reason4unlock" formControlName="reason4unlock" [(ngModel)]="userInputs.reason4unlock" autocomplete="off" (ngModelChange)="onTextValueChange($event)"></textarea>
                  <p>{{remainingLength}}/256</p>
                </div>
                <vdl-error *ngIf="payloadForm.get('reason4unlock').invalid">{{getValidatorError("reason4unlock") | translate}}</vdl-error>
              </vdl-form-field>
          </div>
          <div class="col-xs-8 margin-l20" [hidden]="showTips===false">
              <div class="popup margin-t50">
                  <ul class="no-list" style="padding:10px 10px;">
                      <li>
                          <strong>{{ 'pay-load.appliance-uid.title' | translate}}</strong>
                      </li>
                      <li>{{ 'pay-load.appliance-uid.rule-1' | translate}}</li>
                  </ul>
              </div>
              <div *ngIf="!isExternalUser" class="popup margin-t40">
                <ul class="no-list" style="padding:10px 10px;">
                    <li>
                        <strong>{{ 'pay-load.one-time-password.title' | translate}}</strong>
                    </li>
                    <li>{{ 'pay-load.one-time-password.rule-1' | translate}}</li>
                    <li>{{ 'pay-load.one-time-password.rule-2' | translate}}</li>
                </ul>
              </div>
              <div class="popup popup-height-140 margin-t20">
                  <ul class="no-list" style="padding:10px 10px;">
                      <li>
                          <strong>{{ 'pay-load.password-policy.title' | translate}}</strong>
                      </li>
                      <li>{{ 'pay-load.password-policy.rule-1' | translate}}</li>
                      <li>{{ 'pay-load.password-policy.rule-2' | translate}}</li>
                      <li>{{ 'pay-load.password-policy.rule-3' | translate}}</li>
                      <li>{{ 'pay-load.password-policy.rule-4' | translate}}</li>
                      <li>{{ 'pay-load.password-policy.rule-5' | translate}}</li>
                  </ul>
               </div>
              <div class="popup margin-t40">
                <ul class="no-list" style="padding:10px 10px;">
                  <li>
                    <strong>{{ 'pay-load.reason4unlock.title' | translate}}</strong>
                  </li>
                  <li>{{ 'pay-load.reason4unlock.rule-1' | translate}}</li>
                  <li>{{ 'pay-load.reason4unlock.rule-2' | translate}}</li>
                  <li>{{ 'pay-load.reason4unlock.rule-3' | translate}}</li>
                </ul>
              </div>
            </div>
          <div [hidden]="showTips===true" class="col-xs-100 margin-l20 margin-t20" [hidden]="!authDone">
            <via-portal-app-terminal [testSK]="_text" [appliancesn]="_appliancesn" ></via-portal-app-terminal>
          </div>
      </div>
  </form>
</div>
<div class="panel-actions" [hidden]="!authDone || !otpCheckDone">
  <div class="panel-buttons padding-r20">
      <button vdl-secondary-button (click)="resetAction()" >
          {{ 'pay-load.response.reset' | translate}}
      </button>
      <button vdl-primary-button id="save" class="margin-l10  button-width" (click)="onGenerate()" [disabled]="disableSaveButton()">
          {{ 'pay-load.response.generate' | translate}}
      </button>
  </div>
</div>
<div id="prebootstrap-overlay" name="po-payload" [hidden]="!showProgress" style="opacity: .8;">
    <div id="prebootstrap-loader"></div>
</div>
